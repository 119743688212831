<template>
  <div>
    <v-card style="width: 100vw; overflow-x: hidden;" class="rounded-xl mt-5">
      <v-toolbar
        fixed
        :color="template.colors.primary"
        :style="`
          padding-top: env(safe-area-inset-top);
          height: 80px + env(safe-area-inset-top);
          color: ${template.colors.inline_primary_text};
        `"
      >
        <v-toolbar-title>
          Anmeldung bearbeiten
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          :color="template.colors.inline_primary_text"
          @click="closing()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row justify="center" class="mt-4" v-if="anmeldung">
          <v-col cols="11" class="py-0">
            <v-text-field
              filled
              :label="
                template.var.verband == 'DEU' ? 'ID (optional)' : 'Startnummer'
              "
              v-model="anmeldung.startnummer"
              rounded
            ></v-text-field>
          </v-col>
          <v-col cols="5" class="py-0 pr-0">
            <v-text-field
              class="rounded-l-xl rounded-r-0"
              filled
              label="Vorname"
              :rules="[rules.required, rules.name]"
              v-model="anmeldung.person.vorname"
              rounded
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="py-0 pl-0">
            <v-text-field
              class="rounded-l-0 rounded-r-xl"
              filled
              label="Nachname"
              :rules="[rules.required, rules.name]"
              v-model="anmeldung.person.name"
              rounded
            ></v-text-field>
          </v-col>
          <v-col cols="11" class="py-0">
            <v-text-field
              class="rounded-b-0 rounded-t-xl"
              filled
              label="Strasse + Nr."
              :rules="[rules.required, rules.street, rules.streetnr]"
              v-model="anmeldung.person.adresse"
              rounded
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="5" class="py-0 pr-0">
            <v-text-field
              class="rounded-t-0 rounded-bl-xl rounded-br-0"
              filled
              label="Postleitzahl"
              :rules="[rules.required, rules.plz]"
              v-model="anmeldung.person.plz"
              rounded
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="py-0 pl-0">
            <v-text-field
              class="rounded-t-0 rounded-bl-0 rounded-br-xl"
              filled
              label="Ort"
              :rules="[rules.required, rules.name]"
              v-model="anmeldung.person.ort"
              rounded
            ></v-text-field>
          </v-col>
          <v-col cols="11" class="py-0" v-if="template.var.verband == 'DEU'">
            <v-text-field
              filled
              label="Nation"
              :rules="[rules.required]"
              v-model="anmeldung.person.nation"
              rounded
            ></v-text-field>
          </v-col>
          <v-col cols="5" class="py-0 pr-0">
            <v-text-field
              class="rounded-r-0 rounded-l-xl"
              filled
              label="Telefon"
              :rules="[rules.required, rules.telefon]"
              v-model="anmeldung.person.telefon"
              rounded
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="py-0 pl-0">
            <v-text-field
              class="rounded-l-0 rounded-r-xl"
              filled
              label="E-Mail"
              :rules="[rules.required, rules.email]"
              v-model="anmeldung.person.email"
              rounded
            ></v-text-field>
          </v-col>
          <v-col cols="11" class="py-0">
            <v-text-field
              filled
              label="Funktion"
              :rules="[rules.required]"
              v-model="anmeldung.person.aktivitaet"
              rounded
            ></v-text-field>
          </v-col>
          <v-col cols="11" class="py-0">
            <v-select
              filled
              rounded
              multiple
              chips
              :label="template.var.verband == 'DEU' ? 'Gruppen' : 'Disziplinen'"
              :items="disziplinen"
              item-text="name"
              item-value="id"
              v-model="anmeldung.disziplinen_ids"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ item.name }}</span>
                </v-chip>
                <span v-if="index === 1">
                  (+{{ anmeldung.disziplinen_ids.length - 1 }})
                </span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-btn
              :color="template.colors.primary"
              :style="`color: ${template.colors.inline_primary_text};`"
              large
              block
              rounded
              :disabled="!check_valid()"
              :loading="creating"
              @click="save()"
            >
              Anmeldung speichern
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <v-row justify="center" class="my-4">
      <v-col cols="11" class="pa-0">
        <v-btn
          :color="template.colors.inline_primary_text"
          :style="`color: ${template.colors.primary};`"
          large
          block
          rounded
          :loading="deleting"
          @click="del()"
        >
          Anmeldung löschen
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from 'firebase'
import store from '../../../../../store'
import router from '../../../../../routes/index'

export default {
  name: 'Veranstaltung',
  data() {
    return {
      anz_teilnehmer: 0,
      zuschauerinfo: false,
      veranstaltungedit: false,
      editable: true,
      sportstaette: '',
      anmeldung: '',
      disziplinen: [],
      beginndatum: false,
      beginnuhrzeit: false,
      endedatum: false,
      creating: false,
      bisdatum: false,
      valid: false,
      vondatum: false,
      endeuhrzeit: false,
      rules: {
        required: (value) => !!value || 'Bitte gebe deine Daten ein',
        name: (v) =>
          v.length >= 2 ? true : 'Bitte gebe einen gültigen Namen ein',
        street: (v) =>
          v.length >= 5 ? true : 'Bitte gebe einen gültigen Straßennamen ein',
        streetnr: (v) =>
          v.match(/[0-9]{1,}/)
            ? true
            : 'Bitte gebe eine gültige Hausnummer ein',
        telefon: (v) =>
          v.match(/[0-9]{5,}/)
            ? true
            : 'Bitte gebe eine gültige Telefonnummer ein',
        plz: (v) =>
          v.match(/[0-9]{5}/)
            ? true
            : 'Bitte gebe eine gültige Postleitzahl ein',
        email: (v) =>
          v.match(
            /^((?!\.)[\w-_.]*[^.])(@\w+)((?!\.)[\w-_.]*[^.])(\.\w+(\.\w+)?[^.\W])$/,
          )
            ? true
            : 'Bitte gebe eine gültige E-Mail Adresse ein',
      },
      counter: 0,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      veranstaltung: 'veranstaltung',
      template: 'template',
    }),
  },
  mounted() {
    firebase
      .firestore()
      .collection('User')
      .doc(this.user.data.uid)
      .collection('Veranstaltung')
      .doc(this.veranstaltung.id)
      .collection('Anmeldung')
      .doc(this.$route.params.anmeldung)
      .get()
      .then((doc) => {
        this.anmeldung = doc.data()
        this.anmeldung.id = doc.id
      })
    firebase
      .firestore()
      .collection('User')
      .doc(this.user.data.uid)
      .collection('Veranstaltung')
      .doc(this.veranstaltung.id)
      .collection('Disziplin')
      .orderBy('beginn.datum', 'asc')
      .orderBy('beginn.uhrzeit', 'asc')
      .onSnapshot((snap) => {
        this.disziplinen = []
        snap.forEach((doc) => {
          this.disziplinen.push({
            name:
              doc.data().name +
              (doc.data().altersklasse
                ? ' (' + doc.data().altersklasse + ')'
                : ''),
            daten: doc.data(),
            id: doc.id,
          })
        })
      })
  },
  methods: {
    closing() {
      router.push('/wettkampf/' + this.veranstaltung.id + '/anmeldungen')
    },
    parse_datum(datum) {
      if (datum) {
        var d = datum.split('-')
        return d[2] + '.' + d[1] + '.' + d[0]
      } else {
        return datum
      }
    },
    addNull(val) {
      if (val >= 10) {
        return val
      } else {
        return '0' + val
      }
    },
    check_valid() {
      if (this.anmeldung.person) {
        return true
      } else {
        return false
      }
    },
    get_selected_disziplinen() {
      var selection = []
      for (let i = 0; i < this.disziplinen.length; i++) {
        for (let j = 0; j < this.anmeldung.disziplinen_ids.length; j++) {
          if (this.anmeldung.disziplinen_ids[j] == this.disziplinen[i].id) {
            selection.push(this.disziplinen[i])
          }
        }
      }
      return selection
    },
    async save() {
      this.creating = true
      this.anmeldung.disziplinen = this.get_selected_disziplinen()
      if (this.check_valid()) {
        firebase
          .firestore()
          .collection('User')
          .doc(this.user.data.uid)
          .collection('Veranstaltung')
          .doc(this.veranstaltung.id)
          .collection('Anmeldung')
          .doc(this.$route.params.anmeldung)
          .update(this.anmeldung)
          .then((docRef) => {
            this.creating = false
            this.closing()
          })
      } else {
        this.creating = false
      }
    },
    async del() {
      this.deleting = true
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Veranstaltung')
        .doc(this.veranstaltung.id)
        .collection('Anmeldung')
        .doc(this.$route.params.anmeldung)
        .delete()
        .then(() => {
          this.deleting = false
          this.closing()
        })
    },
  },
}
</script>
